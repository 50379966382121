<template>
  <div>
    <v-card class="mx-auto" tile>
      <v-overlay v-if="loading.page" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-toolbar color="light">
        <v-toolbar-title>Temas disponibles</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          dense
          v-model="pagination.filter"
          :append-outer-icon="'mdi-magnify-expand'"
          outlined
          clearable
          label="Buscar tema"
          type="text"
          hide-details
          @click:clear="(pagination.filter = ''), setListData()"
          @click:append-outer="setListData()"
        ></v-text-field>

        <v-divider vertical class="mx-3"></v-divider>

        <!-- BOTON PARA ABRIR FORMULARIO DE PERSONALIZACION -->
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="ResetLogo(), (formDialog = true)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Crear nuevo tema</span>
        </v-tooltip>
      </v-toolbar>

      <!-- LISTADO DE TEMAS -->
      <CustomList
        listClasses="form-box small"
        headerText="Seleccione un tema para visualizar su contenido"
        :items="GetCustomList"
        :keys="GetCustomKeys"
        @select-item="handlerSelectItem"
      />
      <v-card-text class="elevation-2">
        <v-pagination
          :value="pagination.current"
          circle
          :length="pagination.pages"
          :total-visible="7"
          @input="setPage"
        ></v-pagination>
      </v-card-text>
    </v-card>
    <FormDialog
      v-model="formDialog"
      @close="handlerCloseModal"
      :buttonText="selected ? 'Actualizar' : 'Guardar'"
      :title="
        selected
          ? 'Actualizar un Tema corporativo'
          : 'Crear un nuevo Tema corporativo'
      "
      :loading="loading.modal"
      @action="handlerFormChange"
    >
      <CustomForm
        ref="formComponent"
        :keys="GetCustomKeys"
        :panel-classes="panelClass"
        :data="selected"
        :mobile="isMobile"
        @open-template="openTemplate"
      />
    </FormDialog>

    <!-- PREVISUALIZAR PLANTILLA -->
    <v-dialog v-model="previewDialog" scrollable max-width="850px">
      <v-card>
        <v-card-title primary-title>
          <span>{{
            template?.[GetCustomKeys.template.options.text] ??
            "Vista previa plantilla"
          }}</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" small fab text @click="previewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p class="template-border pa-3 font-weight-bold">
            {{ template?.[GetCustomKeys.template.options.head] }}
          </p>
          <p
            v-html="template?.[GetCustomKeys.template.options.body] ?? ''"
            class="template-border px-3 py-6"
            style="min-height: 350px"
          ></p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import FormDialog from "@/components/Modal/fullscreenModal.vue";
import CustomForm from "@/components/Forms/business/customizationForm.vue";
import CustomList from "@/components/Lists/customizationList.vue";
export default {
  name: "CustomizationPage",
  data: () => ({
    page: "04",
    loading: {
      page: false,
      modal: false,
    },
    pagination: {
      current: 1,
      pages: 1,
      limit: 10,
      filter: "",
    },
    formDialog: false,
    previewDialog: false,
    selected: null,
    template: null,
  }),
  components: {
    FormDialog,
    CustomForm,
    CustomList,
  },
  created() {
    this.setup();
  },
  computed: {
    ...mapGetters("Customization", ["GetCustomList", "GetCustomKeys"]),

    paginationKeys() {
      return this.$store.getters.GetPaginationKeys;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    panelClass() {
      return this.$store.state.panelClass;
    },
  },
  methods: {
    ...mapActions("Customization", [
      "REQUEST_CUSTOMIZATIONS",
      "SAVE_CUSTOMIZATIONS",
      "UPDATE_CUSTOMIZATIONS",
    ]),
    ...mapMutations("Customization", ["ResetLogo"]),

    // Metodos de establecer data
    async setup() {
      this.$store.commit("setPage", { page: this.page });
      await this.setListData();
    },
    setListData() {
      this.loading.page = true;
      const payload = {
        limit: this.pagination.limit,
        page: this.pagination.current,
        filter: this.pagination.filter,
      };
      this.REQUEST_CUSTOMIZATIONS(payload)
        .then((response) => {
          // Settear valores de la paginacion luego de la consulta
          this.pagination.pages = response[this.paginationKeys.pages];
          this.pagination.current = response[this.paginationKeys.current];
          this.pagination.limit = response[this.paginationKeys.limit];
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading.page = false));
    },
    async setPage(num) {
      this.pagination.current = num;
      await this.setListData();
    },
    // Metodos http
    save(form) {
      const component = this.$refs.formComponent;
      this.loading.modal = true;
      component.loading = true;
      this.SAVE_CUSTOMIZATIONS(form)
        .then((message) => {
          this.handlerCloseModal();
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "success",
          });
          this.setListData();
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(
          () => ((this.loading.modal = false), (component.loading = false))
        );
    },
    update(form) {
      this.loading.modal = true;
      const component = this.$refs.formComponent;
      component.loading = true;
      // Enviar la siguiente data
      const payload = {
        data: form,
        id: this.selected[this.GetCustomKeys.id],
      };
      this.UPDATE_CUSTOMIZATIONS(payload)
        .then((message) => {
          this.handlerCloseModal();
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "success",
          });
          this.setListData();
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(
          () => ((this.loading.modal = false), (component.loading = false))
        );
    },
    // Metodos de acciones
    handlerFormChange() {
      const component = this.$refs.formComponent;
      const formObserver = component.$refs.observer;
      formObserver.validate().then((result) => {
        if (result) {
          if (component.update) {
            this.update(component.form);
          } else {
            this.save(component.form);
          }
        }
      });
    },
    async handlerCloseModal() {
      this.formDialog = false;
      this.selected = null;
      await this.$refs.formComponent.resetForm();
    },
    handlerSelectItem(item) {
      this.selected = item;
      this.formDialog = true;
    },
    openTemplate(item) {
      this.template = item;
      this.previewDialog = true;
    },
  },
};
</script>

<style scoped>
.template-border {
  border: solid 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
</style>
