<template>
  <validation-observer ref="observer">
    <!-- TITULO DE LA PERSONALIZACION -->
    <div class="my-6">
      <validation-provider v-slot="{ errors }" name="Nombre" rules="required">
        <v-text-field
          label="Nombre del Tema"
          hint="Escribe un nombre que describa el tema corporativo"
          persistent-hint
          v-model="form.personalizacion_descripcion"
          :error-messages="errors"
        ></v-text-field>
      </validation-provider>
    </div>

    <v-expansion-panels v-model="panels" multiple>
      <!-- SECCION PERSONALIZACION -->
      <v-expansion-panel class="no-elevation">
        <v-expansion-panel-header :class="panelClasses">
          Personalización corporativa
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="[mobile ? 'pt-3' : 'pt-6 px-6']">
          <!-- DOMINIO -->
          <!-- <h4 class="my-6">Dominio</h4>
          <v-col cols="12" md="8">
            <validation-provider
              v-slot="{ errors }"
              name="dominio"
              rules="required"
            >
              <v-text-field
                hint="Define un nombre para el espacio de trabajo o subdominio"
                persistent-hint
                :suffix="loading ? '' : '.' + $store.state.domain"
                :filled="loading"
                :disabled="loading"
                :loading="loading"
                v-model="form[keys.domain]"
                :error-messages="errors"
                color="primary"
              ></v-text-field>
            </validation-provider>
          </v-col> -->
          <!-- LOGO -->
          <h4 class="my-6">Logo del canal</h4>
          <v-col cols="12" md="8">
            <v-fade-transition mode="out-in">
              <v-tooltip
                bottom
                v-if="GetLogo !== '' && !fileChangeMode"
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    bordered
                    color="primary"
                    icon="mdi-pencil"
                    overlap
                    class="selectable"
                    bottom
                  >
                    <v-img
                      :src="GetLogo"
                      :max-width="mobile ? 220 : 450"
                      alt="Logo del canal"
                      contain
                      @click="fileChangeMode = true"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-badge>
                </template>
                <span>Has clic para cambiar el logo</span>
              </v-tooltip>
              <validation-provider
                v-else
                v-slot="{ errors }"
                name="logo"
                rules="required|image"
              >
                <!-- Componente de cargar archivo -->
                <file-drop
                  @file-selected="handleFileSelected"
                  @remove-file="handlerRemoveFile"
                  :error="errors.length > 0"
                  :file="file"
                  :accept="fileAccept"
                  :extensionText="'Archivos aceptados: .png o .jpeg'"
                  :height="250"
                  ref="fileDropBox"
                  :base64="true"
                ></file-drop>
                <!-- Campo de texto invisible para la validacion -->
                <v-text-field v-show="false" v-model="file"></v-text-field>
                <p class="caption error--text" v-if="errors.length > 0">
                  {{ errors[0] }}
                </p>
              </validation-provider>
            </v-fade-transition>
          </v-col>
          <br />
          <!-- COLOR -->
          <h4 class="my-6">Color base para el canal</h4>
          <v-row>
            <v-col cols="12" md="4" xl="5">
              <v-btn-toggle
                v-model="color.btn"
                class="vertical"
                @change="handlerSelectColorBtn"
              >
                <v-tooltip right v-for="(c, i) in GetColors" :key="i">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      block
                      :color="
                        c[keys.colors.color] ? c[keys.colors.color] : 'white'
                      "
                      :small="mobile"
                      :class="[
                        'elevation-1',
                        isDarkColor(
                          c[keys.colors.color]
                            ? c[keys.colors.color]
                            : '#FFFFFFFF'
                        )
                          ? 'white--text'
                          : 'grey--text text--darken-4',
                      ]"
                      :value="c[keys.colors.value]"
                      >{{ c[keys.colors.text] }}</v-btn
                    >
                  </template>
                  <span>{{ c[keys.colors.description] }}</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" md="6">
              <v-color-picker
                v-model="color.selected"
                @input="handlerSelectColorForm"
                mode="hexa"
                hide-mode-switch
                class="pa-2 elevation-8"
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- SECCION PLANTILLAS DE FIDELIZACION -->
      <v-expansion-panel class="no-elevation">
        <v-expansion-panel-header :class="panelClasses">
          Plantillas de fidelización
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="[mobile ? 'pt-3' : 'pt-6 px-6']">
          <!-- TIPO DE PLANTILLA -->
          <!-- <v-col cols="12" md="7" class="pa-0 mt-3">
            <v-select
              :items="GetTemplates"
              v-model="form.plantilla"
              :item-value="keys.template.value"
              :item-text="keys.template.text"
              outlined
              label="Tipos de plantillas"
            ></v-select>
          </v-col> -->
          <!-- REMITENTE -->
          <h4 class="mb-6">Remitente</h4>
          <v-row :no-gutters="mobile">
            <v-col cols="12" sm="6" md="4" xl="3">
              <v-text-field
                label="Nombre Remitente"
                v-model="form.correo.remitente_nombre"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" xl="3">
              <v-text-field
                label="Correo Remitente"
                v-model="form.correo.remitente_correo"
                outlined
                dense
                :rules="[rules.email]"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- TIPOS DE PLANTILLAS -->
          <v-divider class="mb-3"></v-divider>
          <v-row no-gutters class="mb-3">
            <v-col cols="12" md="6">
              <h4>Plantillas del canal</h4>
            </v-col>
            <v-col cols="12" md="6">
              <p class="caption text-right mb-0">
                <v-icon small color="primary">mdi-information</v-icon> Puede
                hacer clic en
                <v-icon>mdi-clipboard-text-search-outline</v-icon> para
                visualizar la plantilla seleccionada.
              </p>
            </v-col>
          </v-row>

          <v-row :no-gutters="mobile">
            <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
              v-for="(template, index) in GetTemplates"
              :key="index"
            >
              <v-autocomplete
                outlined
                dense
                clearable
                prepend-icon="mdi-clipboard-text-search-outline"
                @click:prepend="
                  handleOpenTemplate(template[keys.template.value])
                "
                :items="template[keys.template.options.key]"
                :item-text="keys.template.options.text"
                :item-value="keys.template.options.id"
                :label="template[keys.template.text]"
                @change="
                  handlerChangeTemplate($event, template[keys.template.value])
                "
                @click:clear="
                  handleRemoveTemplate(template[keys.template.value])
                "
                :value="handleTemplateComparator(template[keys.template.value])"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="no-elevation">
        <v-expansion-panel-header :class="panelClasses">
          Acciones de Venta
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="[mobile ? 'pt-3' : 'pt-6 px-6']">
          <!-- OPCIONES -->
          <h4 class="mb-6">Seleccionar las pantallas del canal a visualizar</h4>

          <v-row :no-gutters="mobile" v-if="GetScreens">
            <v-col
              cols="12"
              md="6"
              v-for="(screen, index) in GetScreens"
              :key="index"
            >
              <h4 class="text-h6">{{ GetDinScreenKeys[index] || index }}</h4>
              <v-divider class="mb-3"></v-divider>
              <v-row no-gutters>
                <v-col v-for="(s, i) in screen" :key="i" cols="12" md="6">
                  <v-checkbox
                    :label="s[keys.screens.text]"
                    :value="s[keys.screens.active]"
                    :true-value="true"
                    :false-value="false"
                    @change="hanlderSelectScreen($event, s)"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!--<v-expansion-panel class="no-elevation">
        <v-expansion-panel-header :class="panelClasses">
          Remitente
        </v-expansion-panel-header>
        <v-expansion-panel-content :class="[mobile ? 'pt-3' : 'pt-6 px-6']">
           OPCIONES 
          <h4 class="mb-6">Ingrese nombre y correo del remitente</h4>

          <v-row :no-gutters="mobile">
            <v-col 
              cols="12"
              sm="6"
              md="4"
              xl="3"
            >
              <v-text-field
                label="Nombre Remitente"
                v-model="form.correo.remitente_nombre"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
            >
              <v-text-field
                label="Correo Remitente"
                v-model="form.correo.remitente_correo"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>-->

      <v-overlay v-if="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-expansion-panels>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { darkorlightStyle } from "@/store/resources/tools";
import { validarEmail } from "@/store/resources/validate.js";
import { baseKeys as ckeys } from "@/store/resources/customizationkeys.js";

export default {
  name: "CustomizationForm",
  props: {
    keys: {
      type: Object,
      default: null,
    },
    panelClasses: {
      type: String,
      default: "",
      description: "Clases CSS anexas a los paneles del formulario",
    },
    mobile: {
      type: Boolean,
      default: false,
      description:
        "Valor que define si el dispositivo de vista es mobile o escritorio",
    },
    data: {
      type: Object,
      default: null,
    },
  },
  components: {
    FileDrop: () => import("@/components/Forms/FileDrop.vue"),
  },
  data: () => ({
    update: false,
    loading: false,
    panels: [0, 1, 2],
    form: {
      personalizacion_descripcion: "",
      colores: [],
      [ckeys.template.getKey]: [],
      // dominio: "",
      logo: {
        nombre_archivo: "",
        extension: "",
        archivo: "",
      },
      acceso_pantallas: [],
      correo: {
        remitente_nombre: "",
        remitente_correo: "",
      },
    },
    color: {
      btn: "",
      selected: "",
    },
    file: "",
    fileAccept: ["image/png", "image/jpeg"],
    fileChangeMode: false,
    rules: {
      email: (value) => validarEmail(value) || "Correo invalido.",
    },
  }),
  created() {
    this.loading = true;
    this.REQUEST_CUSTOMIZATIONS_INFO()
      .then(async (response) => {
        await this.setColors();
        await this.setScreens(response[this.keys.screens.getKey]);
        if (this.data) {
          this.setupData(this.data);
        }
      })
      .catch((message) => {
        this.$store.commit("setSnackbar", {
          active: true,
          text: message,
          top: true,
          right: true,
          color: "error",
        });
      })
      .finally(() => (this.loading = false));
  },
  watch: {
    data: {
      handler(val) {
        if (val) {
          this.setupData(val);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("Customization", [
      "GetColors",
      "GetTemplates",
      "GetLogo",
      "GetScreens",
      "GetDinScreenKeys",
    ]),

    isDarkColor() {
      return darkorlightStyle;
    },
  },
  methods: {
    ...mapActions("Customization", [
      "REQUEST_CUSTOMIZATIONS_INFO",
      "REQUEST_CUSTOMIZATION_BY_ID",
    ]),
    ...mapMutations("Customization", ["ResetLogo"]),

    async setupData(item) {
      this.loading = true;
      this.REQUEST_CUSTOMIZATION_BY_ID(item[this.keys.id])
        .then((data) => {
          this.update = true;
          if (this.keys.colors.getKey in data) {
            this.form[this.keys.colors.getKey].forEach((element) => {
              let color = data[this.keys.colors.getKey].find(
                (e) =>
                  e[this.keys.colors.value] === element[this.keys.colors.value]
              );
              if (color) {
                element[this.keys.colors.color] = color[this.keys.colors.color];
              }
            });
          }
          if (this.keys.sender.getKey in data) {
            this.form[this.keys.sender.getKey].remitente_nombre =
              data[this.keys.sender.getKey].remitente_nombre;
            this.form[this.keys.sender.getKey].remitente_correo =
              data[this.keys.sender.getKey].remitente_correo;
          }
          // Rellenar plantillas
          if (this.keys.template.getKey in data) {
            this.form[this.keys.template.getKey] =
              data[this.keys.template.getKey];
          }
          // if (this.keys.domain in data) {
          //   this.form[this.keys.domain] = data.domainName;
          // }
          if (this.keys.name in data) {
            this.form[this.keys.name] = data[this.keys.name];
          }
          if (this.keys.screens.getKey in data) {
            data[this.keys.screens.getKey].forEach((element) => {
              this.hanlderSelectScreen(
                element[this.keys.screens.active],
                element
              );
            });
          }
          this.form[this.keys.logo.change] = false;
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
    setColors() {
      this.form[this.keys.colors.getKey] = [];
      this.GetColors.forEach((element) => {
        element[this.keys.colors.color] = "white";
        this.form[this.keys.colors.getKey].push(element);
      });
    },
    setScreens(data) {
      this.form[this.keys.screens.getKey] = [];
      this.form[this.keys.screens.getKey] =
        data?.map((e) => {
          e[this.keys.screens.active] = false;
          return e;
        }) ?? [];
    },
    resetForm() {
      this.update = false;
      this.form[this.keys.name] = "";
      // this.form[this.keys.colors.getKey] = []
      this.setColors();
      this.color.btn = "";
      this.color.selected = "";
      // this.form[this.keys.template.getKey] = []

      // this.form[this.keys.domain] = "";
      this.form[this.keys.logo.getKey][this.keys.logo.name] = "";
      this.form[this.keys.logo.getKey][this.keys.logo.type] = "";
      this.fileChangeMode = false;
      this.handlerRemoveFile();
      try {
        delete this.form[this.keys.logo.getKey][this.keys.logo.change];
      } catch {
        console.log("No se ha encontrado el campo" + this.keys.logo.change);
      }
      this.ResetLogo();
    },
    // METODOS DE ACCIONES
    handleFileSelected(file, base64 = null) {
      const fileArray = file.name.split(".");
      if (this.update) {
        this.form[this.keys.logo.change] = true;
      }
      try {
        this.form[this.keys.logo.getKey][this.keys.logo.name] = fileArray[0];
        this.form[this.keys.logo.getKey][this.keys.logo.type] = fileArray[1];
      } catch {
        console.log("No se ha logrado colocar un nombre al archivo.");
      }
      this.form[this.keys.logo.getKey][this.keys.logo.file] = base64
        ? base64.split(",")[1]
        : file;
      this.file = file;
    },
    handlerRemoveFile() {
      this.form[this.keys.logo.getKey][this.keys.logo.file] = "";
      this.file = "";
      if (this.update) {
        this.form[this.keys.logo.change] = false;
      }
    },
    handlerSelectColorBtn(e) {
      if (e !== undefined) {
        try {
          this.color.selected = this.form[this.keys.colors.getKey].find(
            (element) => element[this.keys.colors.value] === e
          )[this.keys.colors.color];
        } catch {
          console.log("Color no encontrado entre los seleccionados");
        }
      }
    },
    handlerSelectColorForm(e) {
      if (!this.loading) this.$emit("change");
      try {
        this.form[this.keys.colors.getKey].find(
          (e) => e[this.keys.colors.value] === this.color.btn
        )[this.keys.colors.color] = e;
      } catch {
        console.log("Color no encontrado en el formulario");
      }
    },
    hanlderSelectScreen(e = false, item = null) {
      if (item && this.keys.screens.id in item) {
        let selected = this.form[this.keys.screens.getKey]?.find(
          (element) =>
            element[this.keys.screens.id] === item[this.keys.screens.id]
        );
        if (selected) {
          selected[this.keys.screens.active] = e;
        }
      }
    },
    handlerChangeTemplate(item, template) {
      if (item) {
        const keys = this.keys.template;
        const checkTemplate = this.form[keys.getKey]?.find(
          (e) => e[keys.form.template] === template
        );
        if (checkTemplate) {
          checkTemplate[keys.form.option] = item;
        } else {
          const data = {
            [keys.form.template]: template,
            [keys.form.option]: item,
          };
          this.form[keys.getKey].push(data);
        }
      }
    },
    handleRemoveTemplate(template) {
      const keys = this.keys.template;
      this.form[keys.getKey] = this.form[keys.getKey].filter(
        (e) => e[keys.form.template] !== template
      );
    },
    handleTemplateComparator(template) {
      const keys = this.keys.template;
      const value = this.form[keys.getKey]?.find(
        (e) => e[keys.form.template] === template
      );
      if (value) {
        return value[keys.form.option];
      } else {
        return "";
      }
    },
    handleOpenTemplate(template) {
      const keys = this.keys.template;
      const formValue = this.form[keys.getKey]?.find(
        (e) => e[keys.form.template] === template
      );
      const returnTemplate = (data) => {
        return new Promise((resolve, reject) => {
          const error = "No se ha encontrado la base de la plantilla.";
          if (data) {
            const template = this.GetTemplates.find(
              (e) => e[keys.value] === data[keys.form.template]
            );
            if (template) {
              const templateSelected = template[keys.options.key]?.find(
                (e) => e[keys.options.id] === data[keys.form.option]
              );
              if (templateSelected) {
                resolve(templateSelected);
              } else {
                reject(error);
              }
            } else {
              reject(error);
            }
          } else {
            reject(error);
          }
        });
      };

      returnTemplate(formValue)
        .then((response) => {
          this.$emit("open-template", response);
        })
        .catch((message) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        });
    },
  },
};
</script>

<style></style>
